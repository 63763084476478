<template>
  <el-dialog v-dialog-drag title="选择属性" :visible="visible" :close-on-click-modal="false" width="300" @close="close"
    append-to-body>
    <div>
      属性名称：<el-input v-model="propName" :style="{ width: '200px', 'margin-right': '5px' }" />
      <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
    </div>
    <div>
      <border-box v-for="(prop, propIndex) in propData" v-show="!prop.IsHidden" :pid="prop.PID"
        :key="prop.PID + '-' + propIndex" :title="prop.PropName" :showCheckbox="true" @checkAllChange="checkAllChange">
        <span v-for="(propValue, propValueIndex) in prop.PropValueList" :key="propIndex + propValueIndex"
          :class="['prop-value', propValue.IsChecked ? 'selected' : '']" @click="handleClick(propValue)">{{
            propValue.ValueName }}</span>
      </border-box>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="save">确定</el-button>
      <el-button @click="close">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { submit } from "@/api/base";
import BorderBox from "../../../components/common/BorderBox.vue";

export default {
  components: { BorderBox },
  name: "PropSelector",
  data() {
    return {
      visible: false,
      propName: "",
      propData: [],
      vIDs: [],
      param: {},
    };
  },
  methods: {
    init(vIDs, param) {
      this.visible = true;
      this.vIDs = vIDs;
      this.param = param;
      this.$nextTick(async () => {
        const { data } = await submit("/api/product/prop/getAllList", {
          vIDs: this.vIDs,
        });
        this.propData = data;
      });
    },
    async search() {
      this.propData.forEach((prop) => {
        let isHidden = this.propName
          ? !prop.PropName.includes(this.propName)
          : false;
        if (prop.IsHidden != isHidden) {
          prop.IsHidden = isHidden;
        }
      });
    },
    checkAllChange(data) {
      let prop = this.propData.find((item) => item.PID == data.pid);
      if (prop) {
        (prop.PropValueList || []).forEach((item) => {
          item.IsChecked = data.checked;
        });
      }
    },
    handleClick(propValue) {
      propValue.IsChecked = !propValue.IsChecked;
    },
    save() {
      let columns = [];
      let rows = [];
      this.propData.forEach((prop) => {
        let existChecked = false;
        let newRows = [];
        prop.PropValueList.forEach((propValue, index) => {
          if (propValue.IsChecked) {
            if (rows.length > 0) {
              rows.forEach((row) => {
                let newRow = { ...row };
                newRow["Column_" + prop.PID] = propValue.ValueName;
                newRow["Column_Value_" + prop.PID] = propValue.VID;
                let specArray = JSON.parse(newRow.Spec);
                specArray.push({
                  PID: prop.PID,
                  PropName: prop.PropName,
                  VID: propValue.VID,
                  ValueName: propValue.ValueName,
                });
                newRow.Spec = JSON.stringify(specArray);
                newRow.SpecName += "-" + propValue.ValueName;
                newRow.SpecAlter += "$" + propValue.VID;
                newRows.push(newRow);
              });
            } else {
              let newRow = {};
              newRow.Operation = null;
              newRow["Column_" + prop.PID] = propValue.ValueName;
              newRow["Column_Value_" + prop.PID] = propValue.VID;
              newRow.Sku = "";
              newRow.Barcode = "";
              newRow.Price = this.param.price;
              newRow.UserPrice = this.param.userPrice;
              newRow.Cost = this.param.cost;
              newRow.MinimumPrice = this.param.minimumPrice;
              newRow.IsPresell = 0;
              newRow.Weight = this.param.weight;
              newRow.Memo = "";
              newRow.Spec = JSON.stringify([
                {
                  PID: prop.PID,
                  PropName: prop.PropName,
                  VID: propValue.VID,
                  ValueName: propValue.ValueName,
                },
              ]);
              newRow.SpecName = propValue.ValueName;
              newRow.SpecAlter = propValue.VID + "";
              newRow.SpecID = 0;
              newRows.push(newRow);
            }
            existChecked = true;
          }
        });
        if (existChecked) {
          rows = newRows;
          columns.push({
            label: prop.PropName,
            prop: "Column_" + prop.PID,
            type: "text",
            width: "100px",
            PID: prop.PID,
          });
        }
      });
      if (columns.length > 0) {
        columns.unshift({
          label: "操作",
          prop: "Operation",
          type: "operation",
          width: "50px",
          buttons: [
            {
              type: "primary",
              label: "删除",
              method: "deleteSpec",
            },
          ],
        });
        columns.push({
          label: "规格编码",
          prop: "Sku",
          type: "input",
          width: "130px",
        });
        columns.push({
          label: "条形码",
          prop: "Barcode",
          type: "input",
          width: "130px",
        });
        columns.push({
          label: "吊牌价",
          prop: "Price",
          type: "input-decimal",
          width: "80px",
        });
        columns.push({
          label: "销售价格",
          prop: "UserPrice",
          type: "input-decimal",
          width: "80px",
        });
        columns.push({
          label: "成本价",
          prop: "Cost",
          type: "input-decimal",
          width: "80px",
        });
        columns.push({
          label: "最低售价",
          prop: "MinimumPrice",
          type: "input-decimal",
          width: "80px",
        });
        columns.push({
          label: "是否预售",
          prop: "IsPresell",
          type: "checkbox-int",
          width: "80px",
        });
        columns.push({
          label: `重量（${this.param.weightUnit}）`,
          prop: "Weight",
          type: "input-decimal(3)",
          width: "100px",
        });
        columns.push({
          label: "备注",
          prop: "Memo",
          type: "input",
          width: "130px",
        });
      }
      this.$emit("setSpec", columns, rows);
      this.close();
    },
    close() {
      this.propName = "";
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.prop-value {
  padding: 5px 18px;
  border: 1px solid #ccc;
  display: inline-block;
  margin: 0 10px 10px 0;
  cursor: pointer;

  &.selected {
    position: relative;
    border-color: #0075e3;

    &::before {
      content: "";
      position: absolute;
      right: -1px;
      bottom: -1px;
      border: 10px solid #0075e3;
      border-top-color: transparent;
      border-left-color: transparent;
    }

    &::after {
      content: "";
      width: 4px;
      height: 8px;
      position: absolute;
      right: 1px;
      bottom: 3px;
      border: 2px solid #fff;
      border-top-color: transparent;
      border-left-color: transparent;
      transform: rotate(45deg);
    }
  }
}
</style>
